
import axios from "axios";
import { Notify } from 'notiflix/build/notiflix-notify-aio';



console.log("REACT_APP_ENVIROMENT", process.env.REACT_APP_ENVIROMENT)
var API_ENDPOINT;
var IMAGE_ENDPOINT;
if (process.env.REACT_APP_ENVIROMENT === "staging") {
  API_ENDPOINT = 'http://ec2-13-48-13-58.eu-north-1.compute.amazonaws.com:5001';
  IMAGE_ENDPOINT = 'https://mubaaderrealtor.s3.us-east-2.amazonaws.com/';
} else if (process.env.REACT_APP_ENVIROMENT === "production") {
  API_ENDPOINT = 'https://app.mubaaderealtor.com';
  IMAGE_ENDPOINT = 'https://mubaaderrealtor.s3.us-east-2.amazonaws.com/';
} else {
  API_ENDPOINT = 'http://localhost:5000';
  IMAGE_ENDPOINT = 'https://mubaaderrealtor.s3.us-east-2.amazonaws.com/';

}
export const IMAGE_URL = IMAGE_ENDPOINT
export const API_URL = API_ENDPOINT;

export const maxSizeInBytes = 10 * 1024 * 1024; // 10MB maximum file size



export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')







export const uploadFileServer = async (data) => {
  try {
    var response = await axios.post(`/upload/s3`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}
