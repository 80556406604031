import React from "react"
export const Language = {
    en: {
        welcome: "Welcome",
        logout: "Logout",
        subtitle: "At a glance summary of your account.",
        recentList: "Recent Listing",
        recentPayment: "Recent Payments",
        havetotal: "You have total",
        records: "Records",
        goback: "Go Back",
        exportcsv: "Export CSV",
        view: "View",
        personalinfo: "Personal Information",
        selectuserstatus: "Select User Status",
        fullName: "Full Name",
        email: "Email",
        mobile: "Mobile",
        delete: "Delete",
        filter: "Filter",
        fromDate: "From Date",
        toDate: "To Date",
        viewedit: "View/Edit",
        addNew: "Add New",
        saveChanges: "Save Changes",
        fillAll: "Fill all the required Fields",
        submit: "Submit",
        reset: "Reset",
        appName: "Application Name",
        appLogo: "Application Logo",
        favicon: "Favicon",
        adminLogo: "Admin Logo",
        appemail: "Application Email",
        address: "Contact Address",
        address_ar: "Contact Address Arabic",
        instagramLink: "Instagram Link",
        facebookLink: "Facebook Link",
        youtubeLink: "Youtube Link",
        whatsappLink: "Whatsapp Link",
        authTitle: "Auth Title",
        authInfo: "For Mobile App Login Screens",
        authTitle_ar: "Auth Title Arabic",
        authSubTitle: "Auth Subtitle",
        authSubTitle_ar: "Auth Subtitle Arabic",
        freePost: "Free Ads",
        freeExpiryDays: "Free Ads Expiry Days",
        privacyLink: "Privacy Policy",
        privacyLink_ar: "Privacy Policy Arabic",
        termsLink: "Terms & Condition",
        termsLink_ar: "Terms & Condition Arabic",

        update: "Update",
        paymentGateway: "Payment GateWay Setup",
        paymentClient: "Client Key",
        paymentSecret: "Client Secret Key",
        paymentMode: "Mode",
        updatePassword: "Update Password",
        newpassword: "New Password",
        newpassword1: "Confirm Password",
        siteSetting: "Site Setting",
        //PROEPRTY INFO
        propertyInfo: "Property Information",
        basics: "Basics",
        listingType: "Listing Type",
        category: "Category",
        propertyTitle: "Property Title",
        propertyLocation: "Property Location",
        propertySDesc: "Property Short Description",
        propertyLDesc: "Property Long Description",
        propertySDesc_ar: "Property Short Description Arabic",
        propertyLDesc_ar: "Property Long Description Arabic",
        country: "Country",
        viewOnMap: "View on Map",
        rentPrice: "Price",
        rentType: "Type",
        featuredAd: "Is Featured Ad ?",
        imageGallery: "Image & Video Gallery",
        propertyFeatures: "Property Features",
        video: "Video",
        images: "Images",
        features: "Features",
        yes: "Yes",
        no: "No",
        loadingTxt: "Loading....",
        seoDetails: "Seo Details",
        sellRequest: "Sell Request",
        propertyImage: "Property Images",
        addFeature: "Add Features",
        edit: "Edit",
        //Toast Status
        userUpdate: "User Status Updated",
        errorTxt: "Error Occured Try Again",
        recordAdded: "Record Added Successfully",
        recordEdit: "Record Edited Successfully",
        recordDelete: "Record Deleted Successfully",
        propertyStatus: "Property Status Updated",
        notificationText: "Notification Sent Successfully",
        fileUploaded: "File Uploaded Successfully",
        settingUpdate: "Setting Updated Successfully",
        passwordUpdate: "Password Updated Successfully",
        //LOGIN PAGE
        emailAddress: "Email Address",
        password: "Password",
        signIn: "Sign in",
        adminSignIn: "Admin Sign-In",
        accessThe: "Access the",
        accessSub: "panel using your email and passcode.",
        otpverification: "OTP Verification",
        otpSub: "Enter the verification code we sent to",
        verifyOtp: "Verify OTP",
        didntget: "Didn’t get the code ?",
        resend: "Resend",
        otpResend: 'OTP Sent Successfully',
        enterOTP: "Enter the OTP",
        seosetup: "SEO Setup",
        pageName: "Page Name",
        metaTitle: "Meta Title",
        metaDescription: "Meta Description",
        metaKeywords: "Meta Keywords",
        canonicalUrl: "Canonical URL",
        robotsMeta: "Robots Meta",
        aboutTxt: "Footer Text",
        aboutTxt_ar: "Footer Text Arabic",
        cname: "Contact Person Name",
        cemail: "Contact Person Email",
        cmobile: "Contact Person Mobile",
        cphoto: "Contact Person Photo",
        aboutUs: "About Us Text",
        aboutUs_ar: "About Us Text Arabic",
        sellPage: "Sell Page Setup",
        sellHeroTitle: "Hero Title",
        sellHeroTitle_ar: "Hero Title Arabic",
        sellHeroDesc: "Hero Description",
        sellHeroDesc_ar: "Hero Description Arabic",
        sellSectionTitle: "Section Title",
        sellSectionTitle_ar: "Section Title Arabic",
        sellSectionDesc: "Section Description",
        sellSectionDesc_ar: "Section Description Arabic",
        sellPageFeature: "Sell Page Features",
        title: "Title",
        title_ar: "Title Arabic",
        desc: "Description",
        desc_ar: "Description Arabic",
        homeTitle:"home title",
        homeTitle_ar:"home title arabic",
        homeDesc:"home Description",
        homeDesc_ar:"home Description arabic",



    },
    ar: {
        welcome: "مرحباً",
        logout: "تسجيل خروج",
        subtitle: "في لمحة ملخص حسابك.",
        recentList: "القائمة الأخيرة",
        recentPayment: "المدفوعات الأخيرة",
        havetotal: "لديك المجموع",
        records: "السجلات",
        goback: "عُد",
        exportcsv: "تصدير CSV",
        view: "منظر",
        personalinfo: "معلومات شخصية",
        selectuserstatus: "حدد حالة المستخدم",
        fullName: "الاسم الكامل",
        email: "بريد إلكتروني",
        mobile: "متحرك",
        delete: "يمسح",
        filter: "منقي",
        fromDate: "من التاريخ",
        toDate: "ان يذهب في موعد",
        viewedit: "معاينة ما تم تحريره",
        addNew: "اضف جديد",
        saveChanges: "حفظ التغييرات",
        fillAll: "املأ جميع الحقول المطلوبة",
        submit: "يُقدِّم",
        reset: "إعادة ضبط",
        appName: "اسم التطبيق",
        appLogo: "شعار التطبيق",
        favicon: "فافيكون",
        adminLogo: "شعار المسؤول",
        appemail: "البريد الإلكتروني للتطبيق",
        address: "عنوان الإتصال",
        address_ar: "الاتصال العنوان الأرابيك",
        instagramLink: "رابط Instagram",
        facebookLink: "رابط الفيسبوك",
        youtubeLink: "رابط يوتيوب",
        whatsappLink: "رابط الواتس اب",
        authTitle: "عنوان المصادقة",
        authInfo: "لشاشات تسجيل الدخول إلى تطبيقات الجوال",
        authTitle_ar: "عنوان العنوان العربي",
        authSubTitle: "الترجمة الفرعية",
        authSubTitle_ar: "الترجمة الفرعية العربية",
        freePost: "إعلانات مجانية",
        freeExpiryDays: "إعلانات مجانية أيام انتهاء الصلاحية",
        privacyLink: "رابط سياسة الخصوصية",
        privacyLink_ar: "سياسة الخصوصية عربي",
        termsLink: "الشروط والشرط",
        termsLink: "المصطلحات والشرط العربي",
        update: "تحديث",
        paymentGateway: "إعداد بوابة الدفع",
        paymentClient: "مفتاح العميل",
        paymentSecret: "المفتاح السري للعميل",
        paymentMode: "وضع",
        updatePassword: "تطوير كلمة السر",
        newpassword: "كلمة المرور الجديدة",
        newpassword1: "تأكيد كلمة المرور",
        siteSetting: "إعداد الموقع",
        //PROEPRTY INFO
        propertyInfo: "معلومات الملكية",
        basics: "الأساسيات",
        listingType: "نوع القائمة",
        category: "فئة",
        propertyTitle: "عنوان الممتلكات",
        propertyLocation: "موقع العقار",
        propertySDesc: "خاصية وصف قصير",
        propertyLDesc: "الوصف الطويل الخاصية",
        propertySDesc_ar: "الممتلكات الوصف القصير العربي",
        propertyLDesc_ar: "الممتلكات الوصف الطويل العربي",
        country: "دولة",
        viewOnMap: "عرض على الخريطة",
        rentPrice: " سعر",
        rentType: "يكتب",
        featuredAd: "هل الإعلان مميز؟",
        imageGallery: "معرض الصور والفيديو",
        propertyFeatures: "خصائص المنشأة",
        video: "فيديو",
        images: "الصور",
        features: "سمات",
        yes: "نعم",
        no: "لا",
        loadingTxt: "تحميل....",
        userUpdate: "تحديث حالة المستخدم",
        errorTxt: "حدث خطأ حاول مرة أخرى",
        recordAdded: "سجل أضيفت بنجاح",
        recordEdit: "سجل التحرير بنجاح",
        recordDelete: "سجل حذف بنجاح",
        propertyStatus: "تم تحديث حالة الخاصية",
        notificationText: "إرسال الإخطار بنجاح",
        fileUploaded: "تم رفع الملف بنجاح",
        settingUpdate: "ضبط التحديث بنجاح",
        passwordUpdate: "تم تحديث كلمة السر بنجاح",
        seoDetails: "تفاصيل SEO",
        sellRequest: "طلب بيع",
        propertyImage: "صور الممتلكات",
        addFeature: "إضافة ميزات",
        edit: "يحرر",
        //LOGIN PAGE
        emailAddress: "عنوان البريد الإلكتروني",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        adminSignIn: "تسجيل الدخول",
        accessThe: "الوصول إلى",
        accessSub: "لوحة تستخدم بريدك الإلكتروني ورمز المرور.",
        otpverification: "التحقق من OTP",
        otpSub: "أدخل رمز التحقق الذي أرسلناه إليه",
        verifyOtp: "تحقق من OTP",
        didntget: "لم تحصل على الرمز؟",
        resend: "إعادة إرسال",
        otpResend: 'أرسل OTP بنجاح',
        enterOTP: "أدخل OTP",
        seosetup: "إعداد SEO",
        pageName: "اسم الصفحة",
        metaTitle: "عنوان الفوقية",
        metaDescription: "ميتا الوصف",
        metaKeywords: "كلمات دلالية",
        canonicalUrl: "عنوان URL الكنسي",
        robotsMeta: "روبوتات ميتا",
        aboutTxt: "نص تذييل",
        aboutTxt_ar: "نص تذييل اللغة العربية",
        cname: "اسم شخص الاتصال",
        cemail: "البريد الإلكتروني للشخص الإلكتروني",
        cmobile: "الاتصال الشخص المحمول",
        cphoto: "اتصال الشخص",
        aboutUs: "عنا نص",
        aboutUs_ar: "عنا نص عربي",
        sellPage: "بيع إعداد الصفحة",
        sellHeroTitle: "عنوان البطل",
        sellHeroTitle_ar: "لقب البطل العربي",
        sellHeroDesc: "وصف البطل",
        sellHeroDesc_ar: "الوصف البطل العربي",
        sellSectionTitle: "عنوان القسم",
        sellSectionTitle_ar: "عنوان القسم العربي",
        sellSectionDesc: "وصف القسم",
        sellSectionDesc_ar: "وصف القسم العربي",
        sellPageFeature: "بيع ميزات الصفحة",
        title: "عنوان",
        title_ar: "عنوان اللغة العربية",
        desc: "وصف",
        desc_ar: "الوصف العربي",
        homeTitle:" العنوان الرئيسي بالعربي",
        homeTitle_ar:" العنوان الرئيسي بالانجليزي",
        homeDesc:  "الوصف بالانجليزي",
        homeDesc_ar: "الوصف بالعربي",


    }
}